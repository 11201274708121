var app = {
	windowLoaded: function () {
		_main = this;
		var time = 500;
		setTimeout(function(){			
			$('.loading').each(function() {
				$(this).removeClass('loading');
			});
		}, time);		
	},
	windowResized: function () {
		_main = this;
		_main.getViewport();
	},
	windowScrolled: function () {
		_main = this;
		if (_main.viewport == 'desktop') {
			px_top = $(window).scrollTop();		
			if (px_top >= 250) {
				$('header').addClass('mini');
			} else {
				$('header').removeClass('mini');
			}
		}
	},
	cookies: {		
		createCookie: function(name, value, days) {		    
		    _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
		    });
		},
		notification: function(state) {
			_main=app;
			switch (state) {
				case 'show':
					$('footer').append('<div class="cookiesNotification"><p>Na stronie używamy plików cookies.</p><p class="closeNotification">OK &times;</p></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	handleSliders: function() {
		_main.debug?console.log('slider init'):'';
		$('.slider-gallery').slick({
			arrows: false,
			autoplay: true,
			pauseOnFocus: false,
			pauseOnHover: false,
			// centerMode: true,
			// centerPadding: '60px',
  			// slidesToShow: 1,
		});
	},	
	events: function () {
		_main = this;
		$('.main-menu a, .hero .scroll-icon').on('click', function(e) {			
			e.preventDefault();
			var header = $('header').height();
			if (_main.viewport == 'desktop') {
				$("html, body").animate({				
					// scrollTop: $($(this).attr('href')).offset().top - parseInt(header*1.5)
					scrollTop: $($(this).attr('href')).offset().top
				}, "slow");
			} else {
				$("html, body").animate({				
					scrollTop: $($(this).attr('href')).offset().top
				}, "slow");
			}
		});
		$('.slider-gallery').on('click', '.slick-current img' , function() {
			_main.debug?console.log('opening image'):'';
			img_url = $(this).attr('src');
			$('.gallery-zoom').append('<img src="'+img_url+'" alt="">').addClass('show');
		});
		$('.gallery-zoom .close').on('click', function() {
			_main.debug?console.log('closing image zoom'):'';
			$('.gallery-zoom').removeClass('show');
			$('.gallery-zoom img').remove();
		});
		$('.hamburger').on('click', function() {
			$('.main-menu').toggleClass('show');
		});
		if (_main.viewport != 'desktop') {
			$('.main-menu a').on('click', function() {
				$('.hamburger').click();
			});
		}
	},
	getViewport: function () {
		_main = this;
		wh = $(window).width();
		if (wh <= _main.breakpointMobile) {
			_main.viewport = 'mobile';
		} else if (wh > _main.breakpointMobile && wh <= _main.breakpointTablet) {
			_main.viewport = 'tablet';
		} else {
			_main.viewport = 'desktop';
		}
		(_main.debug) ? console.log('viewport: ', _main.viewport) : '';		
	},
	init: function () {
		_main = this;
		_main.getViewport();
		_main.events();			
		_main.handleSliders();			
		_main.cookies.checkCookies();
	},

	debug: true,
	viewport: null,
	globalNavState: false,
	breakpointMobile: 640,
	breakpointTablet: 1100,
	cookieName: 'dasiemowic'
}

$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(document).scroll(function () {
	app.windowScrolled();
});
